/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import { graphql } from 'gatsby'
import { Redirect } from '@reach/router'
import { injectIntl } from 'gatsby-plugin-intl'
import dayjs from 'dayjs'
import 'dayjs/locale/de'
import 'dayjs/locale/sq'
import Img from 'gatsby-image'
import {
  faFacebookSquare,
  faLinkedin,
  faTwitterSquare
} from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Layout from '../../components/layout'
import EarlyAcess from '../../components/EarlyAcess/EarlyAcess'
import Footer from '../../components/Footer/Footer'
import Helmet from '../../components/helmet'

import styles from './BlogPost.module.scss'

const BlogPost = ({ data, pageContext: { intl } }) => {
  const locale = data.markdownRemark.frontmatter.lng
  if (locale !== intl?.language) {
    return <Redirect noThrow to="/404/" />
  }

  const url = `https://onegate.app/${locale}/blog/${data.markdownRemark.frontmatter.path}/`
  return (
    <Layout headerShadow={true}>
      <Helmet
        lang={locale}
        pathName={`${locale}/blog/${data.markdownRemark.frontmatter.path}`}
        title={data.markdownRemark.frontmatter.title}
        titleTemplate={'%s | Onegate Blog'}
        description={data.markdownRemark.frontmatter.leadText}
        meta={[
          {
            property: 'og:type',
            content: 'article'
          },
          {
            property: 'og:image',
            content:
              data.site.siteMetadata.siteUrl +
              data.markdownRemark.frontmatter.image.childImageSharp.resize.src
          },
          {
            property: 'og:image:height',
            content:
              data.markdownRemark.frontmatter.image.childImageSharp.resize
                .height
          },
          {
            property: 'og:image:width',
            content:
              data.markdownRemark.frontmatter.image.childImageSharp.resize.width
          },
          {
            property: 'og:image:alt',
            content: `Onegate - ${data.markdownRemark.frontmatter.title}`
          },
          {
            name: 'twitter:image:alt',
            content: `Onegate - ${data.markdownRemark.frontmatter.title}`
          },
          {
            property: 'twitter:card',
            content: 'summary_large_image'
          }
        ]}
      />
      <div className="container">
        <div className={styles.root}>
          <div className={styles.postsContainer}>
            <div className={styles.toolbar}>
              <div className={styles.share}>
                <a
                  target="_blank"
                  rel="noreferer"
                  href={`https://www.facebook.com/sharer/sharer.php?u=${url}`}
                >
                  <FontAwesomeIcon icon={faFacebookSquare} />
                </a>
                <a
                  target="_blank"
                  rel="noreferer"
                  href={`https://twitter.com/share?url=${url}`}
                >
                  <FontAwesomeIcon icon={faTwitterSquare} />
                </a>
                <a
                  target="_blank"
                  rel="noreferer"
                  href={`https://www.linkedin.com/shareArticle?url=${url}`}
                >
                  <FontAwesomeIcon icon={faLinkedin} />
                </a>
              </div>
              <div className={styles.date}>
                <p>
                  {dayjs(data.markdownRemark.frontmatter.date)
                    .locale(locale)
                    .format('MMM DD, YYYY')}
                </p>
              </div>
            </div>
            <h1 className={styles.title}>
              {data.markdownRemark.frontmatter.title}
            </h1>
            <Img
              fluid={
                data.markdownRemark.frontmatter.image.childImageSharp.fluid
              }
            />
            <div
              className={styles.content}
              dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}
            ></div>
          </div>
        </div>
      </div>
      <EarlyAcess />
      <Footer />
    </Layout>
  )
}

export default injectIntl(BlogPost)

export const BlogPostTemplateQuery = graphql`
  query BlogPostTemplateQuery($slug: String) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      fields {
        slug
      }
      frontmatter {
        lng
        path
        title
        leadText
        date
        image {
          childImageSharp {
            fluid(maxWidth: 728) {
              ...GatsbyImageSharpFluid
            }
            resize(width: 1200, height: 630, quality: 80) {
              src
              width
              height
            }
          }
        }
      }
      html
    }
  }
`
